import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from './pages/home'
import Contact from './pages/contact';
import Connect from './pages/contact/connect-for-support';
import Navigation1 from './components/navbar/navbar';
import Footer from './components/footer'
import ScrollToTop from './components/scoll-to-top';
import Hosting from './pages/solutions/hosting';
import SdOrder from './pages/utilities/sd-order';
import CyberOffice from './pages/features/sd-cyberoffice';
import DispatchLinks from './pages/features/sd-dispatchlinks';
import Pricing from './pages/utilities/pricing';
import NotFound from './pages/utilities/not-found';
import Styles from './pages/styles';
import PrivacyPolicy from './pages/utilities/PrivacyPolicy';
import EULA from './pages/utilities/EULA';
import TermsOfUse from './pages/utilities/TermsOfUse';
import WrongRemote from './pages/miscellaneous/wrong-remote';
import Overview from './pages/features/Overview';
import SdMobile from './pages/features/sd-mobile';
import Features from './pages/features/featuresX';
import SdRevenueBuilder from './pages/solutions/SdRevenueBuilder';
import SDDealer from './pages/solutions/SdDealer';
import FAQ from './pages/utilities/FAQ';
import About from './pages/about';
import SiteMap from './pages/utilities/SiteMap';
import ScheduleDemo from './pages/utilities/ScheduleDemo';
import Downloads from './pages/resources/Downloads';
import HostingOrder from './pages/utilities/hosting-order';
import GmailStatus from './pages/utilities/GmailStatus';
import RosswarePaySignup from './pages/utilities/RosswarePaySignup';
import RosswarePay from './pages/utilities/RosswarePay';
import StandalonePricing from './pages/utilities/standalone-pricing';
import PublicDevTasks from './pages/utilities/PublicDevTasks';

// import Navigation from './components/navbar/Navigation';

class Framework extends React.Component {
	render() {
		return (
			<Router>
				<ScrollToTop />
				<Navigation1 />
				{/* <Navigation /> */}
				<Switch>
					<Route exact path="/" component={Home} />
					{/* FEATURES */}
					<Route path="/overview" component={Overview} />
					<Route path="/features" component={Features} />
					<Route path="/sd-mobile" component={SdMobile} />
					<Route path="/integrations" component={DispatchLinks} />
					<Route path="/sd-cyberoffice" component={CyberOffice} />
					<Route path="/sd-dispatchlinks" component={DispatchLinks} />
					{/* SOLUTIONS */}
					<Route path="/hosting" component={Hosting} />
					<Route path="/sd-revenuebuilder" component={SdRevenueBuilder} />
					<Route path="/sd-dealer" component={SDDealer} />
					{/* UTILITIES */}
					<Route path="/contact" component={Contact} />
					<Route path="/connect" component={Connect} />
					<Route path="/public-development-tasks" component={PublicDevTasks} />

					{/* RESOURCES */}
					<Route path="/downloads" component={Downloads} />
					{/* remove /sd-order if not needed, in favor of /purchase */}
					<Route path="/sd-order/:techCount?/:option?" component={SdOrder} />
					<Route path="/purchase" component={SdOrder} />
					<Route path="/pricing" component={Pricing} />
					<Route path="/pricing-schedule" component={StandalonePricing} />
					<Route path="/styles" component={Styles} />
					<Route path="/privacy-policy" component={PrivacyPolicy} />
					<Route path="/eula" component={EULA} />
					<Route path="/terms" component={TermsOfUse} />
					<Route path="/faq" component={FAQ} />
					<Route path="/about" component={About} />
					<Route path="/sitemap" component={SiteMap} />
					<Route path="/demo" component={ScheduleDemo} />
					<Route path="/hosting-signup" component={HostingOrder} />
					<Route path="/rosswarepay-signup" component={RosswarePaySignup} />
					<Route path="/rosswarepay" component={RosswarePay} />
					{/* MISCELLANEOUS */}
					<Route path="/wrongremote" component={WrongRemote} />
					<Route path="/gmail-status/:success?" component={GmailStatus} />
					<Route path="/*" component={NotFound} />

				</Switch>

				<Footer />
			</Router>
		);
	}
}
export default Framework;
