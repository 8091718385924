import React from 'react';
import DispatchLinksImage from '../../assets/svg/integrations.svg';
import { Link } from 'react-router-dom';
import MetaGroup from '../../components/meta-group';
import { Container } from 'react-bootstrap';
import { FaArrowRight } from 'react-icons/fa';
import DarkCallToAction from '../../components/dark-call-to-action';

export default class DispatchLinks extends React.Component {
	state = {
		hideExtraPricing: true
	}

	toggleExtraPricing = () => {
		const { hideExtraPricing } = this.state;
		if (hideExtraPricing) {
			this.setState({ hideExtraPricing: false });
		} else {
			this.setState({ hideExtraPricing: true });
		}
	}

	render() {
		return (
			<div>
				<MetaGroup
					title="SD-DispatchLinks - 3rd party warranty integrations"
					metaDescription="These automated services integrate you with 3rd party warranty companies such as ServiceBench, ServiceLive, Dispatch.me and more."
					ogImage="%PUBLIC_URL%/logo512.png"
				/>
				<div className="py-6">
                    <Container className="container-sm">
                        <div className="text-center">
                            <h1 className="h2">Stop entering factory warranty work manually</h1>
                            <p className="lead pb-4">This collection of DispatchLink-Utilities directly plug ServiceDesk into certain third-party companies with a two way information flow.</p>
                            <div>
                                <Link className="btn btn-primary btn-icon-right px-5" to="/contact">Contact sales <FaArrowRight /></Link>
                            </div>
                        </div>
                    </Container>
                </div>
				<div className="bg-white border-top">
					<div className="pt-7 pb-4 container">
						<img src={DispatchLinksImage} className="" alt="SD-RevenueBuilder app helps you maintain service contracts and maintenance agreements." />

						<h3 className="text-center mb-4">Managing made easy</h3>
						<p className="">Increasingly, major consumers of service work (such as manufacturers and extended warranty companies) are turning to automation as the method by which to coordinate dispatch and scheduling operations with servicers. When the large entity's call-taking center needs to schedule service for a consumer, they do it themselves, directly on the servicer's behalf.  The resulting order information is then made available to the servicer (via website login or other means).</p>
						<p className="">For the participating servicer, it's been somewhat burdensome because each servicer is required to keep the large entity's server informed of vacancy status -- and to keep it updated as vacancies change. Also each servicer must check regularly to see if service requests have been created, and read the info, and type it manually into his or her local management system. </p>
						<p className="">Our DispatchLink utilities <b>end the pain</b> by fully automating all such aspects of information flow.  Without the servicer lifting a finger.</p>
					</div>
				</div>

				<div className="py-7 bg-white border-bottom">
					<div className="container">
						<div className="row">
							<div className="col-lg">
								<h2 className="mb-4">Features</h2>
								<p className="">This set of DispatchLink Utilities will directly integrate ServiceDesk into the third party companies of your choice. Information flows both ways, from the third-party into ServiceDesk automatically generating jobs and from ServiceDesk back to the third-party to update them on the status of the jobs. All without you having to lift a finger!</p>
							</div>
							<div className="col-lg d-flex align-items-center">
								<div>
									<h2 className="mb-2">Directly integrate with:</h2>
									<ul className="">
										<li>Samsung</li>
										<li>Dispatch.me</li>
										<li>LG</li>
										<li>ServiceBench</li>
										<li>ServiceLive</li>
										<li>Service Power</li>
									</ul>
									<p className="">Get your dispatches via email? Our SD-EmailDispatchReciever can automatically grab dispatches from a specified email address and plug them right into <Link to="/features">ServiceDesk</Link></p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<DarkCallToAction title="Check out our flexible pricing" link="/pricing
				" linkText="Contact to get started" />

			</div>
		)
	}
}
