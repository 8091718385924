import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FaqItem from './faq-item';

class Faq extends Component {
    state = {
        active: false
    }
    render() {
        return (
            <Container className="container-sm py-5">
                <div className="text-center">
                    <h2>Questions &amp; answers</h2>
                    <p className="lead text-muted mb-5">Can’t find the answer here? <Link to="/contact">Contact support</Link></p>
                </div>
                <FaqItem title="Is Rossware for my company?">Rossware's core app is a Windows based business management solution for appliance repair businesses and other similar service call oriented businesses. We have over 30 years of industry experience and are dedicated to finding solutions to elevate our clients and improve the appliance repair industry as a whole.</FaqItem>
                <FaqItem title="Does ServiceDesk run on Windows?">Yes! ServiceDesk, our main core application, runs exclusively on any Windows operating system. We recommend Windows 7+. Our technician mobile application runs on Windows, iOS, or Android.</FaqItem>
                <FaqItem title="What if I only have a Mac?">ServiceDesk, our main core application, runs exclusively on any Windows operating system. If you do not have any Windows devices, the best option is hosting ServiceDesk on a Windows Server.</FaqItem>
                <FaqItem title="Can I view my schedule on a calendar?">Yes! ServiceDesk, our main core application, has a comprehensive Day Planner view to see and schedule jobs.</FaqItem>
                <FaqItem title="Do you integrate with Quickbooks?">Though ServiceDesk does not directly integrate into Quickbooks, does provide data exports for Quickbooks.</FaqItem>
                <FaqItem title="Can I manage my inventory?">Yes! ServiceDesk has a super-powerful setup that makes it easy for you to maintain exactly the inventory you want, in each and every location.  In our MasterPartsPlan interface, you setup your inventory locations (typically each technician’s truck is a location, for example) and indicate what you want to maintain as minimum stock, for each applicable part number, in each location.  When periodically wanting to replenish stock, ServiceDesk shows you precisely what needs to be ordered, whether across-the-board or location specific.  You simply indicate quantity you wish to order, and ServiceDesk creates the order for you.</FaqItem>
                <FaqItem title="Is ServiceDesk a cloud application?">ServiceDesk is a Windows based desktop application that depends on the Windows file structure to operate. This has many advantages such as speed, your ownership of your own data, and security. Though it's not a cloud app by default, you can use it as a cloud app with a simple <Link to="/hosting">Windows server</Link> so you can access your ServiceDesk from anywhere in the world!</FaqItem>
                <FaqItem title="Can ServiceDesk optimize my route?">Yes! Optimize your routes either one at a time, or intiate advanced whole roster optimization powered by AI that takes into account technicians, jobs, timeframes, technician skillsets, and even when each individual lunch break should be to near perfectly route all of your day's jobs. Using this feature is optional and incurs a charge each usage. Please <Link to="/contact">contact sales</Link> to learn more.</FaqItem>
                <FaqItem title="Can ServiceDesk integrate with third parties such as ServiceBench, ServicePower, etc.?">Yes! Automate information flow both ways between the warranty companies and third parties you work with the most. Integrate with ServiceBench, ServiceLive, Dispatch.me, Service Power, LG, Samsung, and pull them directly from emails automatically.</FaqItem>
                <FaqItem title="Can ServiceDesk collect credit card transactions?">Yes! We were first in the industry to create a fully integrated Virtual Terminal.  It works seamlessly for all transactions, whether in ServiceDesk itself or any of our Mobile apps.</FaqItem>
            </Container>
        );
    }
}

export default Faq;