import React from 'react';
import RemoteDesktop from '../../assets/images/remote-desktop.png';
import MetaTags from 'react-meta-tags';
import { Container } from 'react-bootstrap';
import { FaArrowRight, FaRegQuestionCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { MdCheck } from 'react-icons/md';
import ReactTooltip from 'react-tooltip';

export default class Hosting extends React.Component {
	render() {
		return (
			<div>
				<ReactTooltip />
				<MetaTags>
					<title>Server Hosting | Powerful and Affordable Windows Servers</title>
					<meta name="description" content="Modern business is done on the cloud. More businesses are realizing the benefits of the increased security and ability to do business from anywhere that cloud computing provides." />
					<meta property="og:title" content="SD-Mobile - Field Service Technician Mobile App" />
					<meta property="og:image" content="path/to/image.jpg" />
				</MetaTags>
				<div className="py-6">
					<Container className="container">
						<div className="grid-2-col">
							<div>
								<h1>Windows Server Hosting</h1>
								<p className="lead pb-2">Modern business is done on the cloud. More businesses are realizing the benefits of the increased security and flexibility to do business from anywhere that cloud computing provides.</p>
								<p className="lead pb-4">Let us manage the complexities of server hosting for you so you can enjoy the benefits stress free.</p>
								<Link className="btn btn-primary btn-icon-right" to="/hosting-signup">Get started now <FaArrowRight /></Link>
							</div>
							<div className="w-100">
								<img src={RemoteDesktop} alt="Microsoft remote desktop" width="60%" className="float-right" />
							</div>
						</div>
					</Container>
				</div>
				<div className="container py-7">
					<div className="grid-2-col md-gap">
						<div>
							<h3 className="mb-4">Fast, easy, safe</h3>
							<p>We host powerful Windows servers so you and your office can connect remotely to one shared data source. This is a very popular solution for large offices or offices with multiple locations. Our servers are hosted in California and Ohio to make sure there's a location closest to you for the best reaction times. Automated backups occur daily, weekly, and monthly so no matter what happens your data is safe.</p>
						</div>
						<div>
							<h3 className="mb-4">Easily connect with remote desktop apps</h3>
							<p>Connect to your cloud office from your Windows PC, Mac*, even iOS or Android device from anywhere in the world. This is the perfect solution for multiple locations and business owners that need to connect from the outside.</p>
							<p className="small text-muted">*Some functionality is limited from Mac</p>
						</div>
					</div>
				</div>
				<div className="container hide-mobile" id="pricing">
					<h3 className="mb-4">Pricing</h3>
					<p className="mb-4">There is a one time $100 setup fee and an optional one time data migration fee of $149.</p>
					<div className="grid-6-col border-bottom py-2 font-weight-bolder">
						<div>Server tier</div>
						<div className="text-primary">Tier 1</div>
						<div className="text-success">Tier 2</div>
						<div className="text-warning">Tier 3</div>
						<div className="text-danger">Tier 4</div>
						<div className="text-indigo">Tier 5</div>
					</div>
					<div className="grid-6-col border-bottom py-2 font-weight-bold">
						<div className="text-muted">Recommended users <FaRegQuestionCircle data-tip="If you have more users than are recommended, you may experience performance issues on your server. If you have fewer, you may experience superior performance." /> </div>
						<div>1-3</div>
						<div>3-7</div>
						<div>7-12</div>
						<div>12-25</div>
						<div>25+</div>
					</div>
					<div className="grid-6-col border-bottom py-2 font-weight-bold">
						<div className="text-muted">CPU Cores</div>
						<div>2</div>
						<div>2</div>
						<div>4</div>
						<div>8</div>
						<div>16</div>
					</div>
					<div className="grid-6-col border-bottom py-2 font-weight-bold">
						<div className="text-muted">Memory</div>
						<div>4gb</div>
						<div>8gb</div>
						<div>16gb</div>
						<div>32gb</div>
						<div>64gb</div>
					</div>
					<div className="grid-6-col border-bottom py-2 font-weight-bold">
						<div className="text-muted">Included storage <FaRegQuestionCircle data-tip="Additional storage is $10/mo per additional 50gb." /></div>
						<div>150gb</div>
						<div>150gb</div>
						<div>150gb</div>
						<div>150gb</div>
						<div>150gb</div>
					</div>
					<div className="grid-6-col border-bottom py-2 font-weight-bold">
						<div className="text-muted">Layered backups <FaRegQuestionCircle data-tip="Our servers are backed up daily, weekly, and monthly." /></div>
						<div><MdCheck /></div>
						<div><MdCheck /></div>
						<div><MdCheck /></div>
						<div><MdCheck /></div>
						<div><MdCheck /></div>
					</div>
					<div className="grid-6-col border-bottom py-2 font-weight-bold">
						<div className="text-muted">24/7 server support <FaRegQuestionCircle data-tip="For server related emergencies our team is available 24/7." /></div>
						<div><MdCheck /></div>
						<div><MdCheck /></div>
						<div><MdCheck /></div>
						<div><MdCheck /></div>
						<div><MdCheck /></div>
					</div>
					<div className="grid-6-col border-bottom py-2 font-weight-bold">
						<div className="text-muted">Monthly price</div>
						<div>$199</div>
						<div>$299</div>
						<div>$449</div>
						<div>$699</div>
						<div>$1499</div>
					</div>
				</div>
				<div className="container py-7" id="pricing">
					
					<div className="container text-center py-3">
						<Link className="btn btn-lg btn-primary" to="/hosting-signup">Get started now</Link>
					</div>
				</div>
			</div>
		)
	}
}
