import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import MetaGroup from '../../components/meta-group';

class PrivacyPolicy extends Component {
    render() {
        return (
            <div>
                <MetaGroup
                    title="Privacy Policy | Rossware"
                    metaDescription="This Policy details our commitment to protecting the privacy of individuals who visit our Websites and Applications, who are authorized to use our Services."
                    ogImage=""
                    canonical="privacy-policy"
                />
                <div className="py-8 rounded-bottom grey-hero bg-light">
                    <h1 className="text-center">Privacy Policy</h1>
                    <center>Revised April 27, 2022</center>
                </div>
                <div className="py-7">
                    <Container>
                        <p>We at Fullsteam Software Holdings LLC (Rossware Software). ("RWS," “Company,” "we," "us" or "our") know that your privacy is important. This privacy policy ("Policy") is designed to assist you in understanding how we will collect and use the information you provide when you use our websites ("Sites") and other related services (“Services”) on which we post a direct link to this privacy policy.</p>
                        <p>Please read this Policy to learn more about the ways in which we collect and use your information. By using our Services, you consent to our collection, use, and disclosure of your information as described in this Policy.</p>
                        <p>For the purposes of this Privacy Policy, when we refer to "you" or to a "customer" we mean any past, current or prospective customer of RWS, including any visitor to one of our Sites.</p>
                        <h3 className="mt-6 mb-3">Information Collection and Use</h3>
                        <p>We collect personally identifiable information only if specifically and knowingly provided by you. This Privacy Policy statement explains what information we gather, how we gather it and how we use it.</p>
                        <ul>
                            <li>What information we gather;</li>
                            <li>How we gather information;</li>
                            <li>How we use the information;</li>
                            <li>With whom we may share information;</li>
                            <li>Security of the information;</li>
                            <li>Storage of the information;</li>
                            <li>Your rights and choices; and</li>
                            <li>Contact Information.</li>
                        </ul>
                        <p>Please note that we may update this statement without notice from time to time by posting the updated terms on our websites. You are responsible for periodically reading this statement. If you use our websites after we have updated this statement, you acknowledge that you have read the updated terms and consent to our revised privacy practices.</p>
                        <p>By becoming a registered member or otherwise using our websites, you acknowledge that you have read this privacy policy statement and consent to our privacy practices as described in this statement, including our use and disclosure of personally identifiable information for the purposes described below.</p>
                        <p>If you are a resident of the State of California, please also read Your California Privacy Rights below to understand additional rights you may have pursuant to California Civil Code Section 1798.83 and the California Consumer Privacy Act of 2018.</p>
                        <h3 className="mt-6 mb-3">Information we gather and track</h3>
                        <p>We may collect two types of information from you and other registered users of our websites: (1) "personally identifiable information" and (2) “non-personally identifiable information”.</p>
                        <p>"Personally identifiable information" is information that identifies you personally, such as your name, address, telephone number, email address, or company name.</p>
                        <p>"Non-personally identifiable information" can be technical information, such as aggregated tracking information derived mainly by tallying page views throughout our site, or it can be demographic information. Non-personally identifiable information does not identify you personally. If you do provide us with non-personally identifiable information, we may use it for the purposes described in this statement or for any other legal purpose.</p>
                        <p>We do not offer services directed to children.  Should an individual whom we know to be a child under age 18 send personally identifiable information to us, we will take measures to remove that user's personal information from our databases. From time-to-time we may come into contact with persons under the age of 18 as may be necessary in the performance of our contractual obligations. In such instances we take reasonable efforts to not collect or store any personally identifiable data of such persons, but rather de-identify and anonymize such data of persons under the age of 18.</p>
                        <h3 className="mt-6 mb-3">How we gather and track information</h3>
                        <u>Personally Identifiable Information.</u>
                        <p>As part of the registration process we collect your company and/or trade name, contact name, telephone number, principal address and email address.</p>
                        <p>As part of the utilization of the RWS services by you, we store in our databases certain information regarding your customers and prospective customers, including name, telephone number and/or email address. In some cases individual preferences and requirements will also be maintained as part of the customer or potential customer profile.</p>
                        <p>From time-to-time visitors to our websites who are not registered users may request information about our sites and services and provide their name and email address. We collect and retain that information.</p>
                        <u>Non-Personally Identifiable Information.</u>
                        <p>Cookies. We may place a text file called a “cookie” in the browser files of your computer (if you or your browser accept the cookie) to collect information about your activity on our websites. The cookie itself does not contain personally identifiable information. A cookie can’t read data off your hard disk or read cookie files created by other sites. You can refuse cookies by turning them off in your browser but if you do, you may not have access to some areas of our websites, or to the personalized features of our websites. You may also set your browser to warn you before accepting cookies.</p>
                        <p>Log Files. As is true of most web sites, we gather certain information automatically and store it in log files. This information includes Internet protocol (IP) addresses, browser type, Internet service provider (ISP), referring/exit pages, operating system, date/time stamp and clickstream data.</p>
                        <h3 className="mt-6 mb-3">How we use Information we gather and track.</h3>
                        <u>Personally Identifiable Information.</u>
                        <p>We use personally identifiable information that we gather from registered users and store in our databases (whether the personally identifiable information relates to the registered user or any customers or potential customers of the registered user) solely to provide the service and functionality for which registered users subscribe. Under no circumstances do we divulge any personally identifiable information to any other registered user or to any third party without indicating that your information will be shared. So, for example, we use your personally identifiable information:</p>
                        <ul>
                            <li>To authorize your access to appropriate services, pages, screens and data in our system;</li>
                            <li>To send promotional materials to your customers and potential customers that you have requested that we send on your behalf;</li>
                            <li>To communicate with you regarding our sites and services, new services and changes to our sites and services that we may make from time to time;</li>
                            <li>To provide customer and technical service to you;</li>
                            <li>To comply with law, or in the good faith belief that such action is necessary to conform to the requirements of law, or comply with legal process served on us, and to protect and defend our rights or property, including our rights and property and our websites, or act in urgent circumstances to protect the personal safety of you and our other visitors; and</li>
                            <li>To protect against fraud or potential fraud.</li>
                        </ul>
                        <p>We use name and email addresses provided by visitors to our websites who expressly request information from us in order to provide the requested information to them.</p>
                        <u>Non-Personally Identifiable Information.</u>
                        <p>We use non-personally identifiable information that we gather and track to analyze trends, to administer the site, to track users’ movements around the site and to gather demographic information about our user base as a whole.</p>
                        <p>We do not link this automatically collected data to personally identifiable information.</p>
                        <p>We may create aggregate reports on user demographics and traffic patterns for advertisers, sponsors, and partners. This allows our partners to be more effective and allows our users to receive information that is pertinent to their needs. </p>
                        <p>We will not divulge or share any financial or accounting-related data input by our registered users and stored in our databases to any other registered user or to any third party at any time.</p>
                        <h3 className="mt-6 mb-3">With whom we may share information</h3>
                        <p>We may share, as controller or as processor providing services at the direction of our clients, your personal data to third parties who perform services on our behalf, including our technology providers, payment card processor, administrative personnel and providers and professional advisors. </p>
                        <p>RWS uses a limited number of third-party service providers to assist us in providing our services to customers. These third-party providers assist with the transmission of data and provide data storage services. These third parties may access, process, or store personal data in the course of providing their services. RWS maintains contracts with these third parties restricting their access, use and disclosure of personal data.</p>
                        <p>RWS does not receive or store credit card information from registered users or their customers. We use either an outside payment (credit/debit card and ACH) processing company or our parent company, Fullsteam Operations LLC for subscription services and for point-of-sale functionality. The payment processing company does not retain, share, store or use your personally identifiable information for any other purposes, however, we cannot guarantee that there will not be a security breach of the payment processing company.</p>
                        <p>We may disclose information if we have a good faith belief that disclosure is necessary by law or the legal process, to protect and defend our or others’ interests or property, or to enforce agreements you or our clients enter into with us. RWS may be required to disclose personal information in response to lawful requests by public authorities, including to meet national security or law enforcement requirements.</p>
                        <p>We may obtain your written consent from time to time in electronic form by using online agreements or other acknowledgements through our application, including for any other contemplated uses of your personal data not addressed in this Privacy Policy. Please read all online agreements carefully before accepting them.</p>
                        <p>In the event RWS goes through a business transition, such as a merger, acquisition by another company, or sale of all or substantial portion of its assets, your personally identifiable information will likely be among the assets transferred. </p>
                        <h3 className="mt-6 mb-3">Security of the information.</h3>
                        <p>We follow generally accepted industry standards to protect the personally identifiable information as well as the financial data submitted to us, both during transmission and once we receive it. No method of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore, while we strive to use commercially acceptable means to protect your information, we cannot guarantee its absolute security.</p>
                        <p>If we learn of a breach of our security system or processes, we may attempt to notify you electronically so that you can take appropriate protective steps. By using our websites, or providing personally identifiable information to us through them, you agree that we can communicate with you electronically regarding security, privacy, and administrative issues relating to your use of our websites. In the event of a breach, we may post a notice on our websites and/or send you an email at the email address you provided.</p>
                        <p>YOU MAY HAVE ADDITIONAL RIGHTS TO RECEIVE WRITTEN NOTICE OF SECURITY BREACHES UNDER APPLICABLE LAW OF YOUR JURISDICTION.</p>
                        <h3 className="mt-6 mb-3">Storage of Information</h3>
                        <p>We retain the personal data we collect for so long as reasonably necessary to fulfill the purposes for which the data was collected, to perform our contractual and legal obligations, and for any applicable statute of limitations periods for the purposes of bringing and defending claims.</p>
                        <h3 className="mt-6 mb-3">Legal Basis for Processing</h3>
                        <p>We rely on the following legal grounds to process your personal information:</p>
                        <p>Consent. We may use your personal data as described in this Privacy Policy subject to your consent. </p>
                        <p>Performance of a contract. We may need to collect and use your personal information and the personal information of your customers, as applicable, to perform our contractual obligations. When we process personal data on behalf of third parties, we do so pursuant to agreements with such third parties.</p>
                        <p>Legitimate Interests. We may use your personal information for our legitimate interests to provide our services and to improve our services and the content on our application. We process information on behalf of third parties who have legitimate interests in operating their businesses. We may use technical information as described in this Privacy Policy and use personal information for our marketing purposes consistent with our legitimate interests and any choices that we offer or consents that may be required under applicable law.</p>
                        <h3 className="mt-6 mb-3">Your rights and choices</h3>
                        <p>We communicate with our registered members on a regular RWSis via email. For example, we may use your email address to confirm your request, to send you notice of payments, to send you information about changes to our products and services, and to send notices and other disclosures as required by law. Users can opt-out of all email communications from RWS by contacting us. We will process your unsubscribe request as soon as possible, but please be aware that in some circumstances you may receive a few more messages until your request is processed. You also may opt-out of receiving such emails by clicking on the "unsubscribe" link within the text of the applicable email. </p>
                        <p>You may contact us to request information about the personal data we have collected from you and to request the correction, modification or deletion of such personal information, which requests we will do our best to honor subject to any legal and contractual obligations. </p>
                        <p>Our Director for Compliance is responsible for our privacy programs.</p>
                        <p>For information about the personal data we have collected from you and to request the correction, modification or deletion of such personal information, please email <a href="mailto:compliance.pc56@fullsteam.com">compliance.pc56@fullsteam.com</a> or request by mail addressed to:</p>
                        <blockquote>
                            Fullsteam Operations LLC<br />
                            Attn. Compliance<br />
                            540 Devall Drive, Suite 301<br />
                            Auburn, AL 36832
                        </blockquote>
                        <p>Subject to local law, you may have additional rights under the laws of your jurisdiction regarding your personal data, such as the right to complain to your local data protection authority.</p>
                        <p>Data processed: Company provides online tools that our customers use to operate their services businesses, including by providing access to certain of those tools to their own customers. In providing these tools, Company processes data our customers and our customers’ customers submit to our web services or instruct us to process on their behaves. While Company’s customers and their customers’ customers decide what data to submit, it typically includes information about their customers, sales prospects, point of sale services, inventory management, and goods ordering.</p>
                        <p>Purposes of data processing: Company processes data submitted by our customers and our customers’ customers for the purpose of providing Company’s online services to them. To fulfill these purposes, Company may access the data to provide the services, to correct and address technical or service problems, or to follow instructions of the customer who submitted the data, or in response to contractual requirements.</p>
                        <p>Inquiries and complaints: If you believe Company maintains your personal data in the Company web services within the scope of the applicable law of another jurisdiction, you may direct any inquiries or complaints concerning our compliance to our address noted above. Company will respond within 45 days. We are committed to respond to complaints and to provide appropriate recourse at no cost to you.</p>
                        <p>Third parties who may receive personal data: Company uses a limited number of third-party service providers to assist us in providing our services to customers. These third- party providers assist with the transmission of data and provide data storage services. These third parties may access, process, or store personal data in the course of providing their services. Company maintains contracts with these third parties restricting their access, use and disclosure of personal data in compliance with our obligations, and Company may be liable if they fail to meet those obligations and we are responsible for the event giving rise to the damage.</p>
                        <p>Your rights to access, to limit use, and to limit disclosure: In some jurisdictions you may have the right to access your personal data and to limit use and disclosure of your personal data. Company has committed to respect those rights. Because Company personnel have limited ability to access data our customers submit to our services, if you wish to request access, to limit use, or to limit disclosure, please provide the name of the Company customer who submitted your data to our services. We will refer your request to that customer, and will support them as needed in responding to your request.</p>
                        <p>Compelled disclosure: Company may be required to disclose personal information in response to lawful requests by public authorities, including to meet national security or law enforcement requirements.</p>
                        <h3 className="mt-6 mb-3">Third-Party Websites and Advertisers</h3>
                        <p>Our websites may contain links to third-party websites. While we endeavor to work with third-parties that share our respect for user privacy, we are not responsible for the websites or privacy practices of such third-parties. We may also use third-party advertisers, ad networks, and other advertising, marketing, and promotional companies, to serve advertisements on our websites. Such third parties may gather information about your visit to our websites or other websites, monitor your access to or market products or services to you, monitor the ads you view, click-on, or interact with, when they were delivered, and the screens and pages that they are on.</p>
                        <p>We do not endorse these parties, their content, or any products and services they offer. You are responsible for knowing when you are leaving our website to visit a third-party website, and for reading and understanding the terms of use and privacy policy statements for each such third party.</p>
                        <h3 className="mt-6 mb-3">California Residents – Your Privacy Rights</h3>
                        <u>California Information-Sharing Disclosure</u>
                        <p>(As provided by California Civil Code Section 1798.83)</p>
                        <p>A California resident who has provided personal information to a business with whom he/she has established a business relationship for personal, family, or household purposes ("California customer") is entitled to request information about whether the business has disclosed personal information to any third parties for the third parties' direct marketing purposes. In general, if the business has made such a disclosure of personal information, upon receipt of a request by a California customer, the business is required to provide a list of all third parties to whom personal information was disclosed in the preceding calendar year, as well as a list of the categories of personal information that were disclosed.</p>
                        <p>However, under the law, a business is not required to provide the above-described lists if the business adopts and discloses to the public (in its privacy policy statement) a policy of not disclosing customer's personal information to third parties for their direct marketing purposes unless the customer first affirmatively agrees to the disclosure, as long as the business maintains and discloses this policy. Rather, the business may comply with the law by notifying the customer of his or her right to prevent disclosure of personal information and providing a cost free means to exercise that right.</p>
                        <p>As stated in our privacy policy statement, we do not share information with third parties for their direct marketing purposes unless you affirmatively agree to such disclosure -- typically by opting-in to receive information from a third party. To prevent disclosure of your personal information for use in direct marketing by a third party, do not opt-in to such use when you provide personal information on our website. Please note that whenever you opt-in to receive future communications from a third party, your information will be subject to the third-party's privacy policies and practices. If you later decide that you do not want that third party to use your information, you will need to contact the third party directly, as we have no control over how third parties use information. You should always review the privacy policies and practices of any party that collects your information to determine how that party will handle your information.</p>
                        <p>California customers may request further information about our compliance with this law by e-mailing <a href="mailto:compliance.pc56@fullsteam.com">compliance.pc56@fullsteam.com</a>. Please note that we are only required to respond to one request per customer each year, and we are not required to respond to requests made by means other than through this e-mail address. </p>
                        <u>California Do Not Track Disclosure:</u>
                        <p>Our websites do not respond to “do not track” browser signals. Depending on the browser you are using, you may be able to choose to block third party cookies or browse in a private browsing mode. Our websites are accessible even when private browsing is turned on. The information we collect is governed by this Privacy Policy.</p>
                        <h3 className="mt-6 mb-3">California Consumer Additional Information under California Consumer Privacy Act of 2018 (“CCPA”)</h3>
                        <p>You will not receive discriminatory treatment by us for exercising your privacy rights conferred on you by the CCPA. </p>
                        <p>You have certain additional rights regarding your personal information, pursuant to California law. These include the rights to:</p>
                        <ul>
                            <li>receive a list of the categories of personal information we have collected about you; </li>
                            <li>receive a list of the categories of sources from which your personal information was collected; </li>
                            <li>receive a list of the categories of your personal information that we have disclosed for a business purpose; </li>
                            <li>receive a list of the categories of third parties to whom your personal information was disclosed for a business purpose; </li>
                            <li>know the business or commercial purpose for collecting your personal information;</li>
                            <li>receive a list of specific personal information we collected and for what purposes;</li>
                            <li>have your personal information deleted by us and direct our service providers to delete your information. However, it may be retained pursuant to an allowed exception if applicable;</li>
                            <li>know if personal information was collected from sources other than from you and the categories of sources from which the information was obtained; and</li>
                            <li>receive your personal information in a useable electronic format and transmit it to a third party (right to data portability).</li>
                        </ul>
                        <p><i>Please submit your request for information or deletion of information concerning you as noted below.</i> In order to provide you with your requested information or to delete the information which we have concerning you, we must be able to verify that you are the person requesting the information or deletion. With your request please provide at least two items of information which you have previously provided to us for us to use for verification of your identity. If this is not sufficient, we will contact you for additional means of verification.</p>
                        <p>You may designate an authorized agent to make a request for you. If your agent has a power of attorney pursuant to California Probate Code sections 4000 to 4465, please provide proof to the same. If your agent does not have a power of attorney pursuant to California Probate Code sections 4000 to 4465, we will require you to provide the authorized agent written permission to make the request, verify your own identity directly with us and require your agent to submit proof that they are authorized by you to act on your behalf. </p>
                        <p>For information concerning you or for deletion of information concerning you, please email <a href="mailto:compliance.pc56@fullsteam.com">compliance.pc56@fullsteam.com</a> or request by mail addressed to:</p>
                        <blockquote>
                            Fullsteam Operations LLC<br />
                            Attn. Compliance CCPA<br />
                            540 Devall Drive, Suite 301<br />
                            Auburn, AL 36832
                        </blockquote>
                        <h3 className="mt-6 mb-3">Jurisdiction and Contact Information</h3>
                        <p>Fullsteam Software Holdings LLC (Rossware Software) is a Delaware limited liability company. Our websites are controlled and operated from the United States. If you are an individual from any other jurisdiction with laws or regulations governing personal data collection, use, and disclosure that differ from United States laws, please be advised that we may store the information we collect in the United States or in other countries where we or our third-party service providers have operations. Personal data may also be transferred from the country of your residence to other countries, including the United States.</p>
                        <h3 className="mt-6 mb-3">Contact Information</h3>
                        <p>If you have any questions or suggestions regarding our Privacy Policy, please contact us:</p>
                        <p>By email: <a href="mailto:compliance.pc56@fullsteam.com">compliance.pc56@fullsteam.com</a></p>
                        <p>Address:</p>
                        <blockquote>
                            Fullsteam Operations LLC<br />
                            Attn. Compliance<br />
                            540 Devall Drive, Suite 301<br />
                            Auburn, AL 36832
                        </blockquote>
                    </Container>
                </div>
            </div>
        );
    }
}

export default PrivacyPolicy;