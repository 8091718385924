import React from 'react';
import { Link } from "react-router-dom";
import LogoIcon from '../assets/icon-color.svg'
import { FaFacebookF, FaTwitter, FaYoutube } from "react-icons/fa";

export default class Footer extends React.Component {
	render() {
		return (
			<div className="border-top">
				<div className="container">
					<div className="footer-main-grid py-6">
						<div className="hide-mobile">
							<img src={LogoIcon} alt="Rossware Logo" className="crisp-image mr-4" width="32px" />
						</div>
						<div>
							<h6 className="rw-footer-title">Product</h6>
							<Link className="d-block rw-footer-link" to="/" exact="true">Overview</Link>
							<Link className="d-block rw-footer-link" to="/features">Features</Link>
							<Link className="d-block rw-footer-link" to="/integrations">Integrations</Link>
							<Link className="d-block rw-footer-link" to="/pricing">Pricing</Link>
							<Link className="d-block rw-footer-link" to="/demo">Schedule a demo</Link>
						</div>
						<div>
							<h6 className="rw-footer-title">Solutions</h6>
							<Link className="d-block rw-footer-link" to="/sd-mobile">Technician app</Link>
							<Link className="d-block rw-footer-link" to="/sd-cyberoffice">Online scheduling</Link>
							<Link className="d-block rw-footer-link" to="/sd-revenuebuilder">Recurring Service</Link>
							<Link className="d-block rw-footer-link" to="/sd-dealer">Over the counter sales</Link>
							<Link className="d-block rw-footer-link" to="/hosting">Server hosting</Link>
						</div>
						<div>
							<h6 className="rw-footer-title">Resources</h6>
							<Link className="d-block rw-footer-link" to="/contact">Contact us</Link>
							<Link className="d-block rw-footer-link" to="/connect">Connect for Support</Link>
							<a className="d-block rw-footer-link" href="https://help.rossware.com">Knowledge base</a>
							<a className="d-block rw-footer-link" target="_blank" rel="noreferrer" href="https://rossware.betteruptime.com">Platform status</a>
						</div>
						<div>
							<h6 className="rw-footer-title">Company</h6>
							<a className="d-block rw-footer-link" href="tel:800-353-4101">800 353 4101</a>
							<a className="d-block rw-footer-link" href="mailto:hello@rossware.net">hello@rossware.net</a>
							<Link className="d-block rw-footer-link" to="/about">About us</Link>
							<a className="d-block rw-footer-link" href="https://blog.rossware.com">Rossware Blog</a>
							<Link className="d-block rw-footer-link" to="/faq">FAQs</Link>
						</div>
					</div>
							{/* <div>

								</div>
								<Link className="rw-footer-link rw-footer-link-light mr-4" to="/privacy-policy">Privacy Policy</Link>
								<Link className="rw-footer-link rw-footer-link-light" to="/terms">Terms of Service</Link> */}
					<div className="py-5 clearfix">
						<div className="float-left">
							<Link to="/terms" className="footer-bottom-link">Terms of Use</Link>
							<Link to="/privacy-policy" className="footer-bottom-link">Privacy Policy</Link>
							<span className="footer-bottom-link">&#169; {new Date().getFullYear()}, Fullsteam Software Holdings LLC (Rossware Software). All Rights Reserved.</span>
						</div>
						<div className="float-right">
							<a className="d-inline-block rw-footer-link rw-footer-link-light mr-4" href="https://www.facebook.com/RosswareInc"><FaFacebookF /></a>
							<a className="d-inline-block rw-footer-link rw-footer-link-light mr-4" href="https://twitter.com/rosswareinc"><FaTwitter /></a>
							<a className="d-inline-block rw-footer-link rw-footer-link-light mr-0" href="https://www.youtube.com/user/RosswareInc"><FaYoutube /></a>
						</div>
					</div>
					</div>
				</div>


		)
	}
}