import React from 'react'
import DarkCallToAction from '../components/dark-call-to-action'
import { Helmet } from 'react-helmet';
import { Container } from 'react-bootstrap';
import LegacyImg from '../assets/images/about/glade-legacy.png';
import KentonImg from '../assets/images/about/kenton.png';
import KyloImg from '../assets/images/about/kylo-ren.png';
import TeamImg from '../assets/images/about/team.png';
import Masonry from 'react-masonry-css';
import TradeShowImg from '../assets/images/about/tradeshow.png';
import AwardsImg from '../assets/images/about/awards.jpg';

export default class About extends React.Component {
	render() {
		return (
			<div>
				<Helmet>
					<meta name="fragment" content="!" />
					<title>About us | Rossware Software, LLC.</title>
					<meta name="fragment" content="!"></meta>
					<meta name="description" content="We are dedicated to helping the appliance repair industry excel with leading software solutions." />
					<meta property="og:title" content="About us | Rossware Software, LLC." />
					<meta property="og:image" content="%PUBLIC_URL%/logo512.png" />
				</Helmet>
				<div className="container-md py-6">
					<h1 className="mb-3">About us</h1>
					<p className="">Rossware was founded in 2002 with the simple idea -- make a better software for the appliance service industry.</p>
					<p className="mb-5">Our dedicated team is focused on providing the most comprehensive solution and support for efficient management of your business.</p>
					<h1 className="mb-3">Our mission</h1>
					<p className="mb-5">Rossware Software, LLC. is dedicated to the production and robust support of innovative solutions to real and operational servicing needs. We are continuously and rapidly developing and improving. Our biggest input is the real-life experience among thousands of active and intelligent users. To maximize the benefit of feedback from those users, we foster a corporate culture that attracts and retains creative, practical, and energetic employees who are driven to “make computers think” . . . in particular, for the very and specific purpose of making our clients' lives better.</p>
				</div>
				<div className="container-md py-6">
					<Container>
						<Masonry
							breakpointCols={3}
							className="my-masonry-grid"
							columnClassName="my-masonry-grid_column">
							<img src={LegacyImg} alt="Glade at a Tradeshow" className="rounded shadow" />
							<img src={AwardsImg} alt="Rossware's awards" className="rounded shadow" />
							<img src={KentonImg} alt="Kenton showing off a gift from a client" className="rounded shadow" />
							<img src={KyloImg} alt="Aaron dressed up for halloween" className="rounded shadow" />
							<img src={TeamImg} alt="The early Rossware team" className="rounded shadow" />
							<img src={TradeShowImg} alt="Rossware at a tradeshow" className="rounded shadow" />
						</Masonry>
					</Container>
				</div>
				<DarkCallToAction title="Interested in joining our team?" subtitle="Reach out to us!" link="/contact" linkText="Contact" />
			</div>
		);
	}
}
