import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import MetaGroup from '../../components/meta-group';

class TermsOfUse extends Component {
    render() {
        return (
            <div>
                <MetaGroup
                    title="Terms of Use | Rossware"
                    metaDescription="Rossware Terms of Use Policy"
                    ogImage=""
                    canonical="terms"
                />
                <div className="py-8 rounded-bottom grey-hero bg-light">
                    <h1 className="text-center">Terms of Use </h1>
                    <center>Revised April 29, 2022</center>
                </div>
                <div className="py-7">
                    <Container>
                        <p><u>Use and Service.</u> This web site (herein referred to as "Site") provides services to you subject to the following terms and conditions. If you visit, register, participate or shop at this Site, you accept these terms and conditions. Please read them carefully. These Terms of Use (“TOU”) govern your use of this site and are by and between Fullsteam Software Holdings LLC (Rossware Software) (referred to herein as "Rossware", "we", "us", or "our") and you, the user, on behalf of yourself and the user, customer or supplier for which you are visiting this Site (herein referred to as "you" or “your”). By using and accepting the services or functions offered in or by this Site you waive any right to claim ambiguity or error in these TOU. If you do not agree to each and all of these terms and conditions, please do not use this Site and leave the Site immediately.</p>

                        <p>The Site is not intended for the use of children under 18 and no such person is authorized to use it. By using the Site, you are representing that you are at least 18 years old. You also represent, by accessing or using the Site, that you are of legal age to enter into legal agreements.</p>

                        <p><u>Privacy Policy.</u> The personal information you submit on the Site is governed by the Rossware Privacy Policy, which is incorporated herein by reference. Your acceptance of these terms and conditions constitutes your acceptance of the Privacy Policy. If you object to your personal information being used as described in the Privacy Policy, please do not submit information to, use or register to use the Site.</p>

                        <p><u>Registration.</u> Access to portions of the Site is limited to registered customers to whom we have issued a user account and password. If you have been issued a user account and password, you represent to us that all information you provide to us in connection with your registration is accurate and up-to- date. You also agree to maintain and promptly update your registration information to keep it accurate and updated. If you have provided or provide any registration information that is untrue, inaccurate, not current or incomplete or otherwise fail to comply with any of these terms of use, or we have reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete or that you have otherwise failed to comply with any of these terms of use, we have the right, without prior notice to you, to suspend or terminate your account and refuse any and all current or future use of all or any portion of the Site. </p>

                        <p><u>Customer’s Responsibilities.</u> You (i) must keep your account and passwords secure and conﬁdential, (ii) are solely responsible for all activity in your account, (iii) must use commercially reasonable efforts to prevent unauthorized access to your account, and notify us promptly of any unauthorized access, and (iv) may use the Site and the services provided by us only in accordance with these terms of use and applicable law.</p>

                        <p><u>Changes to Site and Terms of Use.</u> We reserve the right, at our sole and absolute discretion, to change, modify, add, or remove portions of these terms at any time without notice and, unless otherwise indicated, such changes will become effective immediately; therefore, please check these terms periodically for changes. Your continued use of the Site following the posting of changes to these TOU will mean you accept those changes. This Site may include technical or other inaccuracies. Changes are made periodically to the information herein. However, Rossware makes no guarantee or commitment to update materials on this site.</p>

                        <p><u>Third Party Websites.</u> Our Site may provide links to third-party web sites, which are not under our control. Rossware makes no representations about third-party web sites. When you access a non-Rossware web site, you do so at your own risk. We are not responsible for the reliability of any data, opinions, advice, or statements made on third-party sites. Rossware provides these links merely as a convenience for you. The inclusion of such links does not imply that Rossware endorses, recommends, or accepts any responsibility for the content of such sites.</p>

                        <p><u>Trademarks and Copyrights.</u> Unless otherwise noted, product names, designs, logos, titles, text, images, domain names, audio and video of/within this Site are the trademarks, service marks, trade names, copyrights or other property (herein referred to as "Intellectual Property") of Rossware. All other unregistered and registered trademarks are the property of their respective owners and are hereby acknowledged. Nothing contained on the Site should be construed as granting, by implication, estoppel, or otherwise, license or right to use any of Rossware’s Intellectual Property displayed on the Site without the express written permission of Rossware.</p>

                        <p>Rossware respects the intellectual property rights of third parties.  In the event that you have a good faith belief that your copyrights have been violated by the use or display of certain content within the Site, it is our policy to investigate and promptly undertake efforts to resolve the issue.   To notify us regarding an alleged copyright violation, you must provide us with all of the following information: (i) a physical or electronic signature of a person authorized to act on behalf of the owner of the exclusive right that is allegedly infringed; (ii) identification of the copyrighted work(s) claimed to have been infringed, and information reasonably sufficient to permit us to locate the material; (iii) information reasonably sufficient to permit us to contact you, such as an address, telephone number, and if available, an electronic mail address at which you may be contacted; (iv) a statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and (v) a statement that the information in the notification is accurate, and under penalty of perjury, that you are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed. For this notification to be effective, you must provide it to Rossware’s designated agent at:</p>
                        <blockquote>
                            Fullsteam Software Holdings LLC (Rossware Software)<br />
                            C/O Fullsteam Operations LLC<br />
                            Attn: Ed Graf<br />
                            540 Devall Drive, Suite 301<br />
                            Auburn, AL 36832
                        </blockquote>
                        <p><u>WARRANTY & CONDITIONS.</u> EXCEPT AS EXPRESSLY PROVIDED OTHERWISE IN AN AGREEMENT BETWEEN YOU AND ROSSWARE, ALL INFORMATION ON THIS SITE IS PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.</p>

                        <p><u>Site Availability.</u> Rossware is not liable for costs or damage if for any reason, any portion of the Site, is not capable of functioning as intended, including infection by computer virus, bugs, tampering, unauthorized intervention, fraud, technical failures, or any other causes beyond the reasonable control of Rossware. Rossware does not warrant the site to be uninterrupted or error free at all times.</p>

                        <p><u>Site Updates & Integrity.</u> While Rossware makes every effort to provide accurate and secure information, we do not guarantee the information. Further, Rossware is not responsible or liable for incorrect or inaccurate information caused by the users or by any of the equipment or programming associated with or utilized in the Site or by any technical or human error which may occur in the processing of any information related to the Site. Rossware does not guarantee security of information that is transmitted, cached, downloaded, stored and/or otherwise replicated outside of the secure environment of this Site.</p>

                        <p><u>Communications with this Site.</u> You are prohibited from posting or transmitting any unlawful, libelous, scandalous, defamatory, inflammatory, obscene, pornographic, profane, or threatening content or any content that could constitute or encourage conduct that would be considered a criminal offense, give rise to civil liability, or otherwise violate any law. Rossware will fully cooperate with any law enforcement authorities or court order requesting or directing Rossware to disclose the identity of or help identify or locate anyone posting any such information or materials.</p>

                        <p><u>Termination of Use.</u> Rossware reserves the right and sole discretion (but not the obligation) to prohibit you and any member, buyer or supplier from using the Site, and to cancel, terminate, modify or suspend the Site or any portion thereof and void such information in case of corrupt or affected administration, security issues, fairness, integrity, or any violation of these terms and conditions. Rossware is held harmless in such an event.</p>

                        <p><u>DISCLAIMER.</u> ROSSWARE ASSUMES NO RESPONSIBILITY FOR ERRORS OR OMISSIONS IN THE INFORMATION OR SOFTWARE OR OTHER DOCUMENTS WHICH ARE REFERENCED BY OR LINKED TO THIS SITE. IN NO EVENT SHALL ROSSWARE BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY KIND, OR ANY DAMAGES WHATSOEVER (INCLUDING WITHOUT LIMITATION, THOSE RESULTING FROM: (1) RELIANCE ON THE MATERIALS PRESENTED, (2) COSTS OF REPLACEMENT GOODS, (3) LOSS OF USE, DATA OR PROFITS, (4) DELAYS OR BUSINESS INTERRUPTIONS, (5) AND ANY THEORY OF LIABILITY, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF INFORMATION ON THIS SITE WHETHER OR NOT ROSSWARE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>

                        <p><u>Governing Law.</u> You agree that all matters relating to your access to or use of this web site shall be governed by the laws of the State of Delaware, without giving effect to applicable principles of conflicts of law to the extent that the application of the laws of another jurisdiction would be required thereby. In case of any dispute related to these TOU, the parties agree to submit to personal jurisdiction in the State of Delaware.  Furthermore, the parties hereby irrevocably and unconditionally submit to the exclusive jurisdiction of any court of the State of Delaware or any federal court sitting in the State of Delaware for purposes of any suit, action or other proceeding arising out of these TOU.  THE PARTIES HEREBY IRREVOCABLY WAIVE ANY AND ALL RIGHTS TO A TRIAL BY JURY IN ANY ACTION, SUIT OR OTHER PROCEEDING ARISING OUT OF OR RELATING TO THE TERMS, OBLIGATIONS AND/OR PERFORMANCE OF THESE TOU. Rossware makes no representation that information on this Site is appropriate for your use. Those who access this Site do so on their own initiative and are responsible for compliance with all applicable law.</p>

                        <p><u>Contact Us.</u> If you have questions about these Terms of Use, please email <a href="compliance@fullsteam.com">compliance@fullsteam.com</a> or request by mail addressed to:</p>

                        <blockquote>
                            Fullsteam Operations LLC<br />
                            Attn. Compliance<br />
                            540 Devall Drive, Suite 301<br />
                            Auburn, AL 36832
                        </blockquote>


                    </Container>
                </div>
            </div>
        );
    }
}

export default TermsOfUse;