import React from 'react'
import { Form, Button, Alert } from 'react-bootstrap'
import ReCAPTCHA from "react-google-recaptcha";
import { MdPhone, MdEmail, MdCast } from 'react-icons/md';
import { Link } from 'react-router-dom';
import MetaGroup from '../components/meta-group';
import { FaArrowRight } from 'react-icons/fa';
import Faq from '../components/faq';
import { submitContactForm } from '../actions/forms';

export default class Contact extends React.Component {
  state = {
    to: 'support@rossware.net',
    from: '',
    subject: '',
    body: '',
    sendSuccess: false,
    captchaGood: false,
  }

  onChangeEmail = (event) => {
    console.log(this.state)
    this.setState({ from: event.target.value });
  }

  onChangeSubject = (event) => {
    if (event.target.value === 'Contacting RosswarePay') {
      this.setState({ to: 'pay@rossware.net' });
    }
    this.setState({ subject: event.target.value });
  }

  onChangeBody = (event) => {
    this.setState({ body: event.target.value });
  }

  onChangeCaptcha = (event) => {
    console.log(event)
    this.setState({ captchaGood: event });
  }

  async handleSubmit() {
    this.setState({ sendSuccess: true });
    const { to, from, subject, body } = this.state;
    await submitContactForm({ to, from, subject, body });
  }

  render() {
    function isEmailValid(email) {
      const value = email.toLowerCase();
      if (!value) { return false }
      if (value.match(/.+?@.+?\..+/)) { return true } else { return false }
    }
    const { from, subject, body, captchaGood, sendSuccess } = this.state;
    var disabled = true;
    if (subject && body && captchaGood && isEmailValid(from)) {
      disabled = false;
    }
    return (
      <div>
        <MetaGroup
          title="Contact us - Rossware Software, LLC."
          metaDescription="Reach out to us, we're here to help you. You can contact us by phone, email, or live chat."
          ogImage="%PUBLIC_URL%/logo512.png"
          canonical="contact"
        />
        <div className="container-md py-6">
          <div className="grid-2-col md-gap">
            <div>
              <h1 className="mb-3">Contact our awesome team</h1>
              <p className="lead mb-5">Mon-Fri 6AM-5PM Pacific</p>
              <div className="bg-light px-4 py-4 rounded">

                <b>Standard support</b>
                <a href="mailto:hello@rossware.net" className="d-block lead text-dark mt-3"><MdEmail /> hello@rossware.com</a>
                <a href="tel:8003534101" className="d-block lead text-dark mt-2"><MdPhone /> 800-353-4101</a>
                <hr className="my-4"/>
                <b><a href="https://rossware.com/rosswarepay">RosswarePay</a> support</b>
                <a href="mailto:pay@rossware.net" className="d-block lead text-dark mt-3"><MdEmail /> pay@rossware.com</a>
                <a href="tel:8883510170" className="d-block lead text-dark mt-2"><MdPhone /> 888-351-0170</a>

                <br />
                <small style={{  }}>We use <a target="_blank" rel="noreferrer" href="https://fullsteam.com/">Fullsteam</a> as our backend PayFac (payments facilitator). While we wish we could always assist directly, we must defer to them for many payments matters: device support, chargebacks, bank account funding, etc.. If you would like to contact them regarding these matters directly, please send them an email at <a href="mailto:payments.support@fullsteam.com">payments.support@fullsteam.com</a>.</small>
                <hr className="my-4"/>
                <Link to="/connect" className="d-block lead text-dark"><MdCast /> Remote Assistance</Link>
              </div>
              <p className="mb-0"></p>
            </div>
            <div className="">
              {sendSuccess &&
                <Alert variant="success" className="py-4">
                  <Alert.Heading>Thanks for reaching out to us!</Alert.Heading>
                  <hr />
                  <div>Please allow us some time to respond to your email.</div>
                </Alert>
              }
              {!sendSuccess &&
                <Form className="text-left">
                  <h3 className="h4 mb-3">Send us a message</h3>
                  <div className="form-group">
                    <div className="form-label">Subject</div>
                    <select className="custom-select" defaultValue="0" onChange={this.onChangeSubject}>
                      <option value="0" disabled>How can we help you?</option>
                      <option value="Contacting RosswarePay">RosswarePay</option>
                      <option value="Contacting support">Product support</option>
                      <option value="Contacting sales">Sales</option>
                      <option value="Contacting RosswarePay">RosswarePay</option>
                      <option value="I want to schedule a demo">Schedule a demo</option>
                      <option value="Contacting other">Other</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <div className="form-label">Email address</div>
                    <Form.Control type="email" onChange={this.onChangeEmail} placeholder="you@company.com" />
                  </div>

                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <div className="form-label">Message</div>
                    <Form.Control as="textarea" rows="3" onChange={this.onChangeBody} />
                  </Form.Group>
                  <div className="float-left mb-3">
                    <ReCAPTCHA
                      sitekey="6LcjVFsUAAAAAAT0mrnR8ONYHMxXbX5a37OGH8LU"
                      onChange={this.onChangeCaptcha}
                    />
                  </div>
                  <Button disabled={disabled} className="btn-icon-right" onClick={() => this.handleSubmit()}>Send message <FaArrowRight /></Button>
                </Form>
              }
            </div>
          </div>
        </div>


        <div className="container-md mt-4 py-6">
          <div className="grid-3-col-cta">
            <div className="text-center">
              <h3 className="mt-0">Remote Assistance</h3>
              <p className="text-muted mb-6">If you are already on the phone with us, or need to initiate a connection on your own, use this link.</p>
              <Link className="btn btn-primary btn-icon-right" to="/connect">Connect now <FaArrowRight /></Link>
            </div>
            {/* <div className="cta-divider d-none d-lg-block">
              <span className="cta-divider-text">or</span>
            </div>
            <div className="text-center">
              <h3 className="mt-0">Emergency Support</h3>
              <p className="text-muted mb-6">If it is outside our normal operating hours and you must have support, we have an option for emergency support.</p>
              <Link className="btn btn-primary-soft btn-icon-right" to="/emergency">Request emergency support <FaArrowRight /></Link>
            </div> */}
          </div>
        </div>

        <Faq />
      </div>
    )
  }
}