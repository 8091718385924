import React from 'react'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Faq from '../../components/faq';
import { RiCheckDoubleFill, RiCheckLine } from "react-icons/ri";
import ReactSlider from 'react-slider'
import ReactTooltip from 'react-tooltip';
import PricingSchedule from '../../components/pricing-schedule';

export default class Pricing extends React.Component {
	state = {
		monthlyPrice: 150,
		monthlySavings: 150,
		supportPrice: 25,
		techCount: 1,
	}

	handleTechCount = (value) => {
		let techCount = value + 1;
		let monthlyPrice = (techCount - 1) * 17.5 + 150;
		let monthlySavings = 325 - monthlyPrice;

		let supportPrice = (techCount - 1) * 2.5 + 25;

		this.setState({
			techCount: techCount,
			monthlyPrice: monthlyPrice.toFixed(2).replace(/[.,]00$/, ""),
			monthlySavings: monthlySavings.toFixed(2).replace(/[.,]00$/, ""),
			supportPrice: supportPrice.toFixed(2).replace(/[.,]00$/, ""),
		});
	}

	handlePaymentMethod = (event) => {
		if (event.target.value === "1") {
			this.setState({
				directPurchase: false
			});
		} else {
			this.setState({
				directPurchase: true
			});
		}
	}

	toggleModal(modal) {
		this.setState({
			showCyberOfficeModal: modal === 'sdco' ? true : false,
			showHostingModal: modal === 'hosting' ? true : false,
		});
	}

	render() {
		const { monthlyPrice, monthlySavings, techCount } = this.state;

		return (
			<div>
				<Helmet>
					<title>Rossware | Pricing</title>
					<meta name="fragment" content="!"></meta>
					<meta name="description" content="Rossware pricing - ServiceDesk pricing scales with your business. ServiceDesk addons are ala carte." />
					<meta property="og:title" content="Pricing - Rossware Software, LLC." />
					<meta property="og:image" href="%PUBLIC_URL%/logo512.png" />
				</Helmet>
				<ReactTooltip />
				<section className="pt-lg-6 pt-md-4">
					<div className="container-md">
						<div className="pre-text">Pricing</div>
						<h1 className="mb-0">Get the tools your company needs.</h1>
						<h1 className="mb-3">Flexible for any team size.</h1>
						<p className="mb-4">Our pricing is flexible based on the size of your business. Select below how many service technicians your company has to see the monthly price.</p>
						<ReactSlider
							className="horizontal-slider"
							min={0}
							max={10}
							thumbClassName="slider-thumb"
							trackClassName="slider-track"
							renderThumb={(props, state) => <div {...props}><span>{state.valueNow + 1 !== 11 ? state.valueNow + 1 : (state.valueNow + 1) + '+'} <span className="text-white-75 ml-1 font-weight-lighter">{state.valueNow + 1 === 1 ? 'technician' : 'technicians'}</span></span></div>}
							onChange={(value) => this.handleTechCount(value)}
						/>
					</div>
				</section>
				<div className="pb-6 pt-3">
					<div className="container-md">
						<div className="">
							<div className="border rounded">
								<div className="bg-white p-4 rounded-top border-bottom">
									<div className="lead-lg font-weight-bolder">ServiceDesk (core software)</div>
								</div>
								<div className="p-4">
									<div className="h2 mb-0">${monthlyPrice}</div>
									<div className="small mb-4">per month</div>
									<Link to={`/sd-order/${techCount}/1`} className="d-block btn btn-primary mb-4">Get Started</Link>
									<div className="text-left">
										<p className="mb-1"><RiCheckDoubleFill className="h4 text-muted m-0 mr-3" />Core ServiceDesk software to run your service business</p>
										<p className="mb-1"><RiCheckDoubleFill className="h4 text-muted m-0 mr-3" />Integrated payments *</p>
										{monthlySavings > 0 && <p className="mb-1"><RiCheckLine className="h4 text-muted m-0 mr-3" />Saving ${monthlySavings}/mo (for having fewer than 11 techs)</p>}
										<small>* Built in payment processing has associated processing fees. <Link to="/rosswarepay">Click here</Link> for more info.</small>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<PricingSchedule />
				<div className="py-5">
					<div className="container">
						<Faq />
					</div>
				</div>

			</div>
		)
	}
}